// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from '../pages/home/Home';
import Scene from '../pages/scene/Scene';
import SceneNew from '../pages/scene/SceneNew';
import Audio from '../pages/home/Audio'

const AppRouter: React.FC = () => {
    
    return (
        <div className='app'>
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/scene" element={<Scene />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/sceneNew" element={<SceneNew />} />
                    <Route path="/audio" element={<Audio />} />
                </Routes>
            </Router>
        </div>
    );
}

export default AppRouter;
