// src/VoiceRecognition.tsx
import React,{ useEffect,useRef, useState} from 'react';

const DECIBEL_THRESHOLD = -20; // 分贝阈值
const SILENCE_DURATION = 5000; // 5秒
const VoiceRecognition = (props:any) => {
  const [decibel, setDecibel] = useState<number>(0);
  const silenceTimerRef = useRef<any>(null);
  const audioContextRef = useRef<AudioContext | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const dataArrayRef = useRef<Uint8Array | null>(null);


  useEffect(() => {
    const initAudio = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        audioContextRef.current = new (window.AudioContext || (window as any).webkitAudioContext)();
        const audioContext = audioContextRef.current;
        const analyser = audioContext.createAnalyser();
        analyser.fftSize = 2048;
        analyserRef.current = analyser;
        const source = audioContext.createMediaStreamSource(stream);
        source.connect(analyser);
        dataArrayRef.current = new Uint8Array(analyser.frequencyBinCount);

        const updateDecibel = () => {
          if (analyserRef.current && dataArrayRef.current) {
            analyserRef.current.getByteTimeDomainData(dataArrayRef.current);
            let sum = 0;
            for (let i = 0; i < dataArrayRef.current.length; i++) {
              const value = dataArrayRef.current[i] / 128 - 1;
              sum += value * value;
            }
            const rms = Math.sqrt(sum / dataArrayRef.current.length);
            const db = 20 * Math.log10(rms);
            setDecibel(Math.max(db, -Infinity));

            if (db < DECIBEL_THRESHOLD) {
              if (!silenceTimerRef.current) {
                silenceTimerRef.current = setTimeout(() => {
                  window.eventBus.emit('end-speak');
                }, SILENCE_DURATION);
              }
            } else {
              if(!props.isListening){
                window.eventBus.emit('start-speak');
              }
              if (silenceTimerRef.current) {
                clearTimeout(silenceTimerRef.current);
                silenceTimerRef.current = null;
              }
            }
          }
        };
        setInterval(()=>{
          updateDecibel();
        },100)
      } catch (err) {
        console.error('Error accessing microphone', err);
      }
    };

    initAudio();

    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
      if (silenceTimerRef.current) {
        clearTimeout(silenceTimerRef.current);
      }
    };
  }, []);


  return (
    <div>
    </div>
  );
};

export default VoiceRecognition;
