import React, { useState, useEffect, useRef } from "react";
import { Paper } from "@mui/material";
import TypingMessage from "../../untils/TypingMessage";
import "./speakChat.scss";
import { useTranslation } from 'react-i18next';
import { zipImages } from '../../untils/until';
import { AudioOutlined, PauseOutlined } from '@ant-design/icons';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import VoiceRecognition from "../voiceRecognition/VoiceRecognition";
import config from '../../config'
const Chat = (props: any) => {
  const [messages, setMessages] = useState<any>([]);
  const [input, setInput] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const messageArr = useRef<any>([]);
  const inputRef = useRef(null);
  const { t } = useTranslation();
  const hasDone = useRef(true);
  const { transcript, listening, finalTranscript, interimTranscript, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();
  const [decibel, setDecibel] = useState<number>(0);
  const [isListening, setIsListening] = useState<boolean>(false);
  const isVoice = useRef(false); //实时修改 看是否正在监听
  const inputVal = useRef('');//输入框内容

  const handleSend = async () => {
    console.log(messageArr.current, messages, '????????????????????msg')
    if (isTyping) return;
    if (input.trim() || inputVal.current) {
      console.log('??????messageCurrent1', messageArr.current, messages);
      messageArr.current = [...messages, { text: input ? input : inputVal.current, sender: "user", typing: 1 }, { typing: 3, text: 1, sender: 'bot' }]
      console.log('??????messageCurrent2', messageArr.current);
      setMessages((prevMessages: any) => {
        messageArr.current  = [...prevMessages, { text: input ? input : inputVal.current, sender: "user", typing: 1 }, { typing: 3, text: 1, sender: 'bot' }]
        return [
          ...prevMessages,
          { text: input ? input : inputVal.current, sender: "user", typing: 1 },
          { typing: 3, text: 1, sender: 'bot' }
        ]
      });
      setTimeout(() => {
        scrollToBottom()
      }, 100)
  
      isVoice.current = false;
      setIsTyping(true);
      const lan = localStorage.getItem("language") === 'EN' ? 'en' : 'cn';
      const val = input ? input : inputVal.current;
      console.log('??????val',val)
      const host = config.host
      let eventSource: any = new EventSource(`${host}qwenStream?userMessage=` + input + '&lan=' + lan);
      setInput("");
      inputVal.current = "";
      let messageStr = "";
      const length = messageArr.current.length - 1;
      eventSource.onmessage = function (event: any) {
        hasDone.current = false;
        const data = JSON.parse(event.data);
        const text = data.output.choices[0].message.content
        messageStr += text;

        const temp = JSON.parse(JSON.stringify(messageArr.current));
        let obj = {};
        obj = {
          text: messageStr,
          sender: 'bot',
          typing: 2
        }
        temp[length] = obj;
        // 逐字输入 设定一个定时滚动到最底部 在输出后在滚动一次 并清除滚动定时器
        if (data.output.choices[0].finish_reason === 'stop') {
          hasDone.current = true;
          eventSource.close();
        }
        setMessages(temp);
        messageArr.current = temp;
      };
    }
  };


  // 监听文本变化
  useEffect(() => {
    if (interimTranscript.trim()) {
      setInput(interimTranscript);
      inputVal.current = interimTranscript;
    }
    if (finalTranscript.trim()) {
      setInput(finalTranscript);
      inputVal.current = finalTranscript;
    }
  }, [interimTranscript, finalTranscript]);

  // 开始监听
  const startListening = () => {
    SpeechRecognition.startListening({ continuous: true });
    setIsListening(true);
    isVoice.current = true;
  };

  // 结束监听
  const endListing = () => {
    SpeechRecognition.stopListening();
    setIsListening(false);

    setTimeout(() => {
      if (finalTranscript.trim()) {
        setInput(finalTranscript);
        inputVal.current = finalTranscript;
      }
      handleSend();
      resetTranscript();
    }, 500);
  }

  const scrollToBottom = () => {
    var bottomElement: any = document.getElementById("chat-box");
    bottomElement.scrollTop = bottomElement.scrollHeight;
  };

  useEffect(() => {
    console.log(messageArr.current, '??????????????messageArr.current')
  }, [messageArr.current])

  useEffect(() => {
    // 默认显示欢迎语 判断是不是第一次打开
    const str = t('scene_1_npc_desc').split('\n')[0];
    const str1 = t('scene_1_npc_desc').split('\n')[1];
    const str2 = t('scene_1_npc_desc').split('\n')[2];
    if (sessionStorage.getItem("has-open-chat")) {
      let temp: any = [{
        text: str,
        sender: 'bot',
        typing: 1
      }, {
        text: str1,
        sender: 'bot',
        typing: 1
      }, {
        text: str2,
        sender: 'bot',
        typing: 1
      }];
      setMessages(temp);
      messageArr.current = temp;
    } else {
      let obj = {};
      obj = {
        text: str,
        sender: 'bot',
        typing: 2
      }
      let temp: any = [];
      temp[0] = obj;
      setMessages(temp);
      messageArr.current = temp;
      setTimeout(() => {
        obj = {
          text: str1,
          sender: 'bot',
          typing: 2
        }
        temp.push(obj)
        setMessages(temp);
        messageArr.current = temp;

        setTimeout(() => {
          obj = {
            text: str2,
            sender: 'bot',
            typing: 2
          }
          temp.push(obj)
          setMessages(temp);
          messageArr.current = temp;
          sessionStorage.setItem("has-open-chat", 'true');
        }, 2100)
      }, 3200)
    }
    console.log(messages,"?????????????????end33",messageArr.current);
    window.eventBus.on('end-speak', ()=>{
      console.log(messages,"?????????????????end1",messageArr.current);
      endListing();
    });
    window.eventBus.on('start-speak', startListening);
  }, [])

  // 关闭弹窗 
  const closeModal = () => {
    window.eventBus.emit('closeNpcChat');
  };

  const handleTypingComplete = (index: any) => {
    if (!hasDone.current) return;

    setIsTyping(false)
    setMessages((prevMessages: any) => {
      const updatedMessages = [...prevMessages];
      updatedMessages[index].typing = 1;
      console.log(updatedMessages, '??????4', prevMessages)
      messageArr.current = updatedMessages;
      return updatedMessages;
    });
  };

  return (
    <div className="chat-dialog1" onClick={closeModal}>
      <div className="close-box" onClick={closeModal}>
        <img src="/assets/images/close-icon.png" alt="" />
      </div>
      <div className="chat-container1" onClick={(e) => e.stopPropagation()}>
        <div className="virtual" style={{ width: "40vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <img src={zipImages("https://oss.tnworld.cn/kunlun/assets/xiu/xiuxiu.png")} alt="" />
        </div>
        <div className="messages" id="chat-box">
          {messages.map((message: any, index: any) => (
            <div key={index} className={`message ${message.sender}`}>
              <Paper className="message-text">
                {message.typing == 2 ?
                  <TypingMessage message={message.text} answering={() => setIsTyping(true)} onComplete={() => handleTypingComplete(index)} isTyping={isTyping} /> : message.typing == 1 ? message.text :
                    <>
                      <span className="dot">.</span>
                      <span className="dot">.</span>
                      <span className="dot">.</span>
                    </>
                }
              </Paper>
            </div>
          ))}
        </div>
        <div className="input-container">
          <input
            ref={inputRef}
            autoFocus={true}
            value={input}
            onFocus={scrollToBottom}
            onChange={(e) => {
              setInput(e.target.value);
            }}
            onKeyDown={(e) => {
              e.key === "Enter" && handleSend();
            }}
            placeholder={isTyping ? "正在输入..." : "请输入..."}
            autoComplete="off"
            disabled={isTyping}
          />

          {!isListening && <AudioOutlined onClick={startListening} />}
          {isListening && <PauseOutlined onClick={endListing} />}
          <img src="/assets/images/arrow-back.png" onClick={handleSend} className="arrow-back" alt="" />
        </div>
      </div>
      <VoiceRecognition isListening={isListening}></VoiceRecognition>
    </div>
  );
};

export default Chat;
