import React, { useState, useEffect } from 'react';
import './partFour.scss';
import ImgDocument from '../../../components/img/ImgDocument';
import { useTranslation } from 'react-i18next';
import { zipImages } from '../../../untils/until';
import { ClassNames } from '@emotion/react';
interface PartFourProps {
    scrollPosition: number; // 新增属性用于接收滚动位置
    mobile: boolean;//是否是移动端
}
const PartFour: React.FC<PartFourProps> = ({ scrollPosition, mobile }) => {
    const { t } = useTranslation();
    // pc端图片数组
    const imgArr = [{
        src: 'https://oss.tnworld.cn/kunlun/assets/home/Moon.png',
        top: '-12%',
        direction: 'X',
        speed: 0.1,
        zIndex: 2,
        num: 2,
        left: '-50%',
        maxScroll: 3.2 * window.innerHeight,
        float: 'down'
    },
    {
        src: 'https://oss.tnworld.cn/kunlun/assets/home/山-4-最后.png',
        top: '0',
        direction: 'X',
        speed: 0.1,
        zIndex: 1,
        num: 5,
        left: '-20%',
        maxScroll: 4 * window.innerHeight,
        float: 'down'
    },
    {
        src: 'https://oss.tnworld.cn/kunlun/assets/home/山-3.png',
        top: '0',
        direction: 'X',
        speed: 0.1,
        zIndex: 3,
        num: 5,
        left: '-20%',
        maxScroll: 4 * window.innerHeight,
        float: 'down'
    },
    {
        src: 'https://oss.tnworld.cn/kunlun/assets/home/山-2.png',
        top: '0',
        direction: 'X',
        speed: 0.1,
        zIndex: 4,
        num: 5,
        left: '-20%',
        maxScroll: 4 * window.innerHeight,
        float: 'down'
    },
    {
        src: 'https://oss.tnworld.cn/kunlun/assets/home/山-1-最前.png',
        top: '0',
        direction: 'X',
        speed: 0.1,
        zIndex: 5,
        num: 5,
        left: '-20%',
        maxScroll: 4 * window.innerHeight,
        float: 'down'
    },
    {
        src: 'https://oss.tnworld.cn/kunlun/assets/home/岛.png',
        top: '0%',
        direction: 'X',
        speed: 0.1,
        zIndex: 6,
        num: 5,
        left: '20%',
        maxScroll: 5 * window.innerHeight
    }];
    // mobile图片数组
    const mobileImgArr = [{
        src: 'https://oss.tnworld.cn/kunlun/assets/mobile-home/p3a月亮.png',
        top: '0%',
        direction: 'X',
        speed: 0.1,
        zIndex: 2,
        num: 2,
        left: '-50%',
        maxScroll: 3.2 * window.innerHeight,
        float: 'down'
    },
    {
        src: 'https://oss.tnworld.cn/kunlun/assets/mobile-home/p4后山.png',
        top: '40%',
        direction: 'X',
        speed: 0.1,
        zIndex: 1,
        num: 5,
        left: '-20%',
        maxScroll: 3.4 * window.innerHeight,
        float: 'down',
        className:'hill-center'
    },
    {
        src: 'https://oss.tnworld.cn/kunlun/assets/mobile-home/p4中山.png',
        top: '40%',
        direction: 'X',
        speed: 0.1,
        zIndex: 3,
        num: 5,
        left: '-23%',
        maxScroll: 3.4 * window.innerHeight,
        float: 'down',
        className:'hill-center'
    },
    {
        src: 'https://oss.tnworld.cn/kunlun/assets/mobile-home/p4前山.png',
        top: '32%',
        direction: 'X',
        speed: 0.1,
        zIndex: 4,
        num: 5,
        left: '-20%',
        maxScroll: 3.4 * window.innerHeight,
        float: 'down',
        className:'hill-center'
        // className:'hill-bottom'
    },
    {
        src: 'https://oss.tnworld.cn/kunlun/assets/mobile-home/p4底山.png',
        top: '0%',
        direction: 'X',
        speed: 0.1,
        zIndex: 2,
        num: 5,
        left: '-20%',
        maxScroll: 3.4 * window.innerHeight,
        float: 'down'
    },
    {
        src: 'https://oss.tnworld.cn/kunlun/assets/mobile-home/p4岛屿.png',
        top: '0%',
        direction: 'X',
        speed: 0.1,
        zIndex: 6,
        num: 5,
        left: '20%',
        maxScroll: 3.5 * window.innerHeight
    }];

    const openMoadl = () => {
        window.eventBus.emit('openModal');
    }

    return ( 
        <>
            {!mobile && <div className="part-4">
                <img className='breach-text' style={{ zIndex: 10 }} src={zipImages("https://oss.tnworld.cn/kunlun/assets/home/p4文字装饰.png")} alt="" />
                <img className='breach-star' style={{ zIndex: 8 }} src={zipImages("https://oss.tnworld.cn/kunlun/assets/home/星星.png")} alt="" />
                <img className='breach-star' style={{ zIndex: 8 }} src={zipImages("https://oss.tnworld.cn/kunlun/assets/home/暖黄.png")} alt="" />
                {
                    imgArr.map((item: any, index: any) => {
                        return (<ImgDocument data={item} scrollPosition={scrollPosition} />)
                    })
                }
                <div className='about-us-box'>
                    <img src={zipImages("https://oss.tnworld.cn/kunlun/assets/home/biaoti.png")} alt="" />
                    <div>
                        {t('home_about_us')}
                    </div>
                </div>
                <div className='page-footer'>
                    <div className='footer-left'>
                        KUNLUN WORLD © 2024
                    </div>
                    <div className='footer-right'>
                        <div className='footer-right-icons'>
                            <img src="/assets/images/X.png" onClick={openMoadl} alt="" />
                            <img src="/assets/images/Discord.png" onClick={openMoadl} alt="" />
                            <img src="/assets/images/Discord-1.png" onClick={openMoadl} alt="" />
                        </div>
                        <div className='footer-right-href'>
                            <a onClick={openMoadl}>Privacy Policy</a>  | <a onClick={openMoadl}>Terms of Use</a>
                        </div>
                    </div>
                </div>
            </div>}
            {mobile &&
             <div className="part-4">
                <img className='breach-text' style={{ zIndex: 10 }} src={zipImages("https://oss.tnworld.cn/kunlun/assets/mobile-home/p4文字.png")} alt="" />
                <img className='breach-star' style={{ zIndex: 8 }} src={zipImages("https://oss.tnworld.cn/kunlun/assets/mobile-home/p4星星.png")} alt="" />
                {
                    mobileImgArr.map((item: any, index: any) => {
                        return (<ImgDocument data={item} scrollPosition={scrollPosition} />)
                    })
                }
                <div className='about-us-box'>
                    <img src={zipImages("https://oss.tnworld.cn/kunlun/assets/home/biaoti.png")} alt="" />
                    <div>
                        {t('home_about_us')}
                    </div>
                </div>
                <div className='page-footer'>
                    {/* <div className='footer-left'>
                        KUNLUN WORLD © 2024
                    </div> */}
                    <div className='footer-right'>
                        <div className='footer-right-icons'>
                            <img src="/assets/images/X.png" onClick={openMoadl} alt="" />
                            <img src="/assets/images/Discord.png" onClick={openMoadl} alt="" />
                            <img src="/assets/images/Discord-1.png" onClick={openMoadl} alt="" />
                        </div>
                        {/* <div className='footer-right-href'>
                            <a onClick={openMoadl}>Privacy Policy</a>  | <a onClick={openMoadl}>Terms of Use</a>
                        </div> */}
                    </div>
                </div>
            </div>}
        </>
    )
};

export default PartFour;